import { graphql, Link } from 'gatsby';
import React from 'react';
// import Dump from '../components/Dump';
import Layout from '../components/Layout';

export default ({ data }) => (
    <Layout>
        <main>
            {data.allMdx.nodes.map(
                ({
                    id, excerpt, frontmatter, fields
                }) => (
                    <div key={id}>
                        <Link to={`/blog${fields.slug}`}>
                            <h1>{frontmatter.title}</h1>
                            <p>{frontmatter.date}</p>
                            <p>{excerpt}</p>
                        </Link>
                    </div>
                )
            ) || <h2>TVL</h2>}
            {data.allMdx.nodes.length <= 0
            && <h1>👨🏻‍💻Working on it...</h1>}
        </main>
    </Layout>
);

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "DD.MM.YYYY")
        }
        fields {
          slug
        }
      }
    }
  }
`;
